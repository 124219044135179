import style from "./HReventlanding.module.css";
import bulat from "../../images/bulat.png";
import serik from "../../images/serik.png";
import sanjar from "../../images/sanjar.png";
import almaz from "../../images/almaz.png";
import bulat_small from "../../images/bulat_small.png";
import serik_small from "../../images/serik_small.png";
import sanjar_small from "../../images/sanjar_small.png";
import almaz_small from "../../images/almaz_small.png";
import * as React from "react";

export default function Speakers(props){
	const { isPhone } = props

	return (
		<div className={style.speakers_wrapper}>
			<div className={style.whiteTitle}>
				Наши спикеры
			</div>
			<div className={style.speakers_cards_wrapper}>
				<div >
					<div>
						<img className={style.speakerCardImg} src={isPhone ? bulat_small : bulat}/>
					</div>
					<div className={style.speakerCardName}>
						Булат<br/>Садыков
					</div>
					<div className={style.speakerCardOrg}>
						Partners büro
					</div>
				</div>

				<div >
					<div>
						<img className={style.speakerCardImg} src={isPhone ? almaz_small : almaz}/>
					</div>
					<div className={style.speakerCardName}>
						Алмаз<br/>Рахимбеков
					</div>
					<div className={style.speakerCardOrg}>
						Uber | Amsterdam
					</div>
				</div>

				<div >
					<div>
						<img className={style.speakerCardImg} src={isPhone ? sanjar_small : sanjar}/>
					</div>
					<div className={style.speakerCardName}>
						Санжар<br/>Кенжеханулы
					</div>
					<div className={style.speakerCardOrg}>
						BEAM.kz
					</div>
				</div>

				<div >
					<div>
						<img className={style.speakerCardImg} src={isPhone ? serik_small : serik}/>
					</div>
					<div className={style.speakerCardName}>
						Серик<br/>Жарданбек
					</div>
					<div className={style.speakerCardOrg}>
						FOQUS
					</div>
				</div>
			</div>
		</div>
	)
}
