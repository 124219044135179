import creatti_logo from '../../images/creatti_logo.png'
import confetti_box from '../../images/confetti_box.png'
import style from './Registration.module.css'

export default function SuccessPage(){
	return <div style={{
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		height: '100%'
	}}>
		<img src={confetti_box}/>
		<div className={style.success_title}>Благодарим вас<br/>за участие!</div>
		<div className={style.success_subtitle}>Не забудьте получить<br/>подарок от нас в 13:00</div>
		<div className={style.copyright}>© FOQUS, 2024</div>
	</div>
}
