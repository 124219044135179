import './App.css';
import * as React from 'react';
import { Layout} from 'antd';
import {Content} from 'antd/es/layout/layout';
import {Outlet} from 'react-router-dom';

function App() {
  return (
    <Layout style={{margin: 0}}>
      <Layout style={{background: '#FFFFFF', margin: 0}}>
        <Content style={{margin: 0}}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;

//FIXME Test push
