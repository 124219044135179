import style from '../LandingFoqus.module.css'
import howItWorks_arrow from '../../../images/howItWorks/howItWorks_arrow.svg'
import howItWorks_1 from '../../../images/howItWorks/howItWorks_1.png'
import howItWorks_2 from '../../../images/howItWorks/howItWorks_2.png'
import howItWorks_3_1 from '../../../images/howItWorks/howItWorks_3_1.png'
import howItWorks_3_3 from '../../../images/howItWorks/howItWorks_3_3.png'
import howItWorks_3_2 from '../../../images/howItWorks/howItWorks_3_2.png'
import howItWorks_arrow_bottom from '../../../images/howItWorks/howItWorks_arrow_bottom.svg'
import howItWorks_arrow_top from '../../../images/howItWorks/howItWorks_arrow_top.svg'
import howItWorks_arrow_pointer from '../../../images/howItWorks/howItWorks_arrow_pointer.svg'
import howItWorks_short_line from '../../../images/howItWorks/howItWorks_short_line.svg'
import howItWorks_splat from '../../../images/howItWorks/howItWorks_splat.png'
import mobile_splat_2 from "../../../images/newLangingSplats/mobile_splat_2.png";

export default function HowItWorks(){
	return <div className={style.howItWorks_wrapper}>
		<div className={style.container}>
			<div className={style.howItWorks_title}>
				How it works
			</div>
			<div className={style.howItWorks_subtitle}>
				from data
				<img src={howItWorks_arrow} className={style.howItWorks_arrow}/>
				to insights
			</div>
			<div className={style.howItWorks_subsubtitle}>
				in 3 easy steps
			</div>
			<div className={style.howItWorks_cards_wrapper}>
				<div className={style.howItWorks_card}>
					<div className={style.howItWorks_card_step}>
						<div className={style.gradient_text}>1 Collect data</div>
					</div>
					<img src={howItWorks_1}/>
					<div>
						<div className={`${style.howItWorks_card_title} ${style.gradient_text}`}>
							Integration
						</div>
						<ul className={style.howItWorks_card_desc}>
							<li className={style.howItWorks_card_desc_point}>existing cameras</li>
							<li className={style.howItWorks_card_desc_point}>CRM , POS, Telegram, QR, 1C</li>
							<li className={style.howItWorks_card_desc_point}>third party: weather, events, etc</li>
						</ul>
					</div>
					<img src={howItWorks_short_line} className={style.short_line}/>
					<img src={howItWorks_arrow_pointer} className={style.short_pointer_bottom}/>
				</div>
				<div className={style.howItWorks_card}>
					<div className={style.howItWorks_card_step}>
						<div className={style.gradient_text}>2 Data processing</div>
					</div>
					<img src={howItWorks_2}/>
					<div>
						<div className={`${style.howItWorks_card_title} ${style.gradient_text}`}>
							Hybrid models
						</div>
						<ul className={style.howItWorks_card_desc}>
							<li className={style.howItWorks_card_desc_point}>computer vision algorithms</li>
							<li className={style.howItWorks_card_desc_point}>decision theory combined with AI</li>
							<li className={style.howItWorks_card_desc_point}>Big Data analytics</li>
						</ul>
					</div>
					<img src={howItWorks_short_line} className={style.short_line}/>
					<img src={howItWorks_arrow_pointer} className={style.short_pointer_bottom}/>
				</div>
				<div className={style.howItWorks_card}>
					<div className={style.howItWorks_card_step}>
						<div className={style.gradient_text}>3 Data output</div>
					</div>
					<div className={style.howItWorks_card_3}>
						<img src={howItWorks_3_1} style={{width: '70%'}}/>
						<div style={{display: 'flex', flexDirection: 'column', width: '20%'}}>
							<img src={howItWorks_3_2}/>
							<img src={howItWorks_3_3}/>
						</div>
					</div>
					<div>
						<div className={`${style.howItWorks_card_title} ${style.gradient_text}`}>
							Interactive web app
						</div>
						<ul className={style.howItWorks_card_desc}>
							<li className={style.howItWorks_card_desc_point}>visualisation on BI dashboards</li>
							<li className={style.howItWorks_card_desc_point}>auto-emailing of management reports</li>
							<li className={style.howItWorks_card_desc_point}>real-time alerts into messengers</li>
						</ul>
					</div>
				</div>
				<img src={howItWorks_arrow_bottom} className={style.howItWorks_arrow_bottom}/>
				<img src={howItWorks_arrow_top} className={style.howItWorks_arrow_top}/>
				<img src={howItWorks_arrow_pointer} className={style.pointer_top}/>
				<img src={howItWorks_arrow_pointer} className={style.pointer_bottom}/>
			</div>
		</div>
		<img src={howItWorks_splat} className={style.howItWorks_splat}/>
		<img src={mobile_splat_2} id={'mobile_splat_2'}/>
	</div>
}
