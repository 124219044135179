import React, {useState} from 'react';
import style from '../LandingFoqus.module.css'
import {PlusOutlined} from '@ant-design/icons';
import faq_plus from '../../../images/faq_plus.svg'
import faq_minus from '../../../images/faq_minus.svg'
import splat_6 from "../../../images/newLangingSplats/splat_6.png";

function Faq() {
	const questions = [
		{
			title: 'What integration systems are available for your service platform?',
			items: ['Integration of the FOQUS service platform is possible through a wide range of systems and platforms including API, SDK, IoT (Analyzing data coming from cameras and sensors. Popular IoT are AWS IoT, Google IoT, and Microsoft Azure IoT), ERP (Enterprise Resource Planning)/CRM (Customer Relationship Management), VMS, NVR, BI, robotics systems, and containerization platforms. Each of these methods helps to tailor the FOQUS CV platform to meet specific business challenges and to maximize the benefits of using AI to analyze visual data.']
		},
		{
			title: 'How do you ensure data security?',
			items: ['Comprehensive data protection on the FOQUS CV platform is guaranteed by security measures such as data encryption in storage, data encryption during network transmission, authentication, local data processing (Edge Computing), access control, as well as continuous monitoring, and system updates. Compliance with regulatory requirements and the use of new technologies such as edge computing deliver strong data protection and minimizes risks to users and the business.']
		},
		{
			title: 'Can customer data be used for analytics or marketing?',
			items: ['The use of customer data for analytics and marketing demands strict legal compliance, transparency, and user consent. It is also important to consider ethical aspects and minimize potential risks in order not to violate users\' rights and to maintain their trust.'
			,'The data collected by the FOQUS CV platform can be different, not all of which are suitable for marketing purposes: aggregated and anonymized data. For marketing analytics, aggregated data that does not contain personal information and does not identify the user can be leveraged. For example, data on the number of store visits or total dwell time in departments can be used to improve customer experience.']
		},
		{
			title: 'Are there free trial periods?',
			items: ['Yes, the CV platform FOQUS allows potential clients to use a free period, to conduct research in pilot mode, from two weeks.']
		},
		{
			title: 'What support is provided during the implementation phase?',
			items: ['Support during the implementation period of CV platforms includes many aspects: from installation and configuration of the equipment to training of personnel, providing real-time technical support, customization of the system to meet business requirements and ensuring data security. An integrated approach to support helps minimize risks and increase implementation efficiency.']
		},
		{
			title: 'What technical support options are available?',
			items: [
				'Hardware installation and customization: Platform experts can assist in the setup of cameras, servers, and other hardware components of the system. This includes properly installing cameras for optimal image capture, configuring video streaming settings and connections to the platform.',
				'Integration with existing infrastructure: Help integrate CV with existing systems such as data management systems (DMS), security systems, POS systems, ERP, and CRM. This involves developing and customizing APIs to ensure compatibility.',
				'System Calibration Support: For a successful implementation, it is important to properly calibrate computer vision systems so that they accurately recognize objects, faces, and movements. CV Platform experts can assist in tuning algorithms and models to improve accuracy.',
				'Customization and adaptation to business needs',
				'Data and analytics level support',
				'Security and compliance support',
				'Post-implementation updates and support',
			],
			listStyle: 'auto'
		},
		{
			title: 'How quickly are technical issues resolved?',
			items: [
				'Technical issues on the CV FOQUS platform are promptly addressed, especially with premium support and advanced remote diagnostic tools. To maximize responsiveness, companies can opt for advanced support packages that offer 24/7 monitoring, real-time assistance, and minimal delays in troubleshooting.',
				'Typical timelines can be summarized as follows:',
				{
					text: 'Simple problems (customizations, software glitches): 30 minutes to a few hours.',
					listStyle: 'disc'
				},
				{
					text: 'Medium problems (configurations, integrations): several hours to one business day.',
					listStyle: 'disc'
				},
				{
					text: 'Complex problems (hardware failures, critical software bugs): one to several business days.',
					listStyle: 'disc'
				},
			]
		},
		{
			title: 'Where are you located and what regions do you serve?',
			items: ['The company is based in Almaty, servicing mainly Almaty and Astana. We can also provide Enterprise services online, and in other cities and regions of Kazakhstan.']
		},
	]

	return (
		<div className={style.faq_wrapper}>
			<div className={style.container}>
				<div className={style.faq_content}>
					<div className={style.faq_title}>
						Get all your questions answered here
					</div>
					<div className={style.faq_questions_wrapper}>
						{
							questions.map(item => {
								return <CustomCollapse key={item.title} title={item.title} items={item.items} listStyle={item.listStyle}/>
							})
						}
					</div>
				</div>
			</div>
			<img src={splat_6} id={'splat_6'}/>
		</div>
	);
}

function CustomCollapse(props) {
	const { title, items, collapseIndex, listStyle = 'none' } = props;
	const [open, setOpen] = useState(false);

	return (
		<div
			className={style.faq_collapse}
			onClick={() => setOpen(!open)}
		>
			<div className={style.faq_collapse_title_wrapper}>
				{
					open ? <img src={faq_minus}/> : <img src={faq_plus}/>
				}
				<div className={style.faq_collapse_title}>{title}</div>
			</div>
			<ul
				className={[
					style.faq_collapse_item_wrapper,
					open ? style.faq_collapse_open : '',
				].join(' ')}
				style={{
					listStyle: listStyle,
				}}
			>
				{items.map((item, index) => {
					return (
						<li
							key={`collapse-item-${collapseIndex}-${index}`}
							className={style.faq_collapse_item}
							style={{
								listStyle: typeof item === 'string' ? 'inherit' : item.listStyle
							}}
						>
							{typeof item === 'string' ? item : item.text}
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default Faq;
