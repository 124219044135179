import style from "../LandingFoqus.module.css";
import footer_linkedin from '../../../images/footer_linkedin.svg'
import footer_slack from '../../../images/footer_slack.svg'
import footer_telegram from '../../../images/footer_telegram.svg'
import footer_whatsapp from '../../../images/footer_whatsapp.svg'
import login_arrow from "../../../images/login_arrow.svg";
import footer_splat from "../../../images/footer_splat.png"
import footer_splat_mobile from "../../../images/footer_splat_mobile.png"


export default function Footer({isPhone, setTab}){
	return <div className={style.footer_wrapper} style={{paddingLeft: '14px'}} id={'contacts'}>
		<div className={style.container}>
			<div className={style.footer_items_warpper}>
				<div className={style.footer_menu}>
					<div className={style.footer_menu_item}>About us</div>
					<a href={'#contacts'} className={style.footer_menu_item}>Contacts</a>
					<div onClick={() => setTab('/blog')} className={style.footer_menu_item}>Blog</div>
				</div>
				<div className={style.footer_info}>
					<div className={style.footer_info_text}>Almaty, 22/5, st. Satpayev, office 202</div>
					<div className={style.footer_info_text}>+7 771 863 77 77</div>
				</div>
				<div className={style.footer_socials_part}>
					<div className={style.footer_socials}>
						<a href={'https://www.linkedin.com/company/foqusin/'} target={'_blank'}><img src={footer_linkedin}/></a>
						<a href={'https://team-iw18119.slack.com/team/U050SG2ET55'} target={'_blank'}><img src={footer_slack}/></a>
						<a href={'https://t.me/SerikJardem'} target={'_blank'}><img src={footer_telegram}/></a>
						<a href={'https://api.whatsapp.com/qr/WCHX3YAZ7YMBO1?autoload=1&app_absent=0'} target={'_blank'}><img src={footer_whatsapp}/></a>
					</div>
					<a target={'_blank'} href={'https://foqus-portal.live'} className={style.login_button}>
						Log in
						<img src={login_arrow} style={{marginLeft: '10px'}} className={style.login_arrow}/>
					</a>
				</div>
			</div>
			<div className={style.footer_subtext}>
				2024, copyright FOQUS
			</div>
		</div>
		<img src={isPhone ? footer_splat_mobile : footer_splat} className={style.footer_splat}/>
	</div>
}
