import {useEffect, useRef, useState} from "react";
import style from './Registration.module.css'
import {Button} from "antd";
import {
	$currentPage,
	$userData,
	changeUserDataEv,
	sendRegistrationDataEv,
	setNextStepEv
} from "../../models/hreventModel";
import {useUnit} from "effector-react";
import CustomSlider from "./CustomSlider";

const steps_titles = {
	0: 'Сделайте фото анфас',
	1: 'Сделайте фото в профиль влево',
	2: 'Сделайте фото в профиль вправо',
	3: 'Сделайте фото наклонив голову вниз'
}

const steps_titles1 = {
  0: 'Сделайте фото анфас',
  1: <div>Сделайте фото в профиль <span style={{fontSize: '30px', padding: '0 5px'}}>👈</span> влево</div>,
  2: <div>Сделайте фото в профиль <span style={{fontSize: '30px', padding: '0 5px'}}>👉</span> вправо</div>,
  3: <div>Сделайте фото наклонив голову <span style={{fontSize: '30px', padding: '0 5px'}}>👇</span> вниз</div>
}

export default function FaceScanPage() {

  const [animation, setAnimation] = useState(false)
	const videoRef = useRef()
	const canvasRef = useRef()
	const changeUserData = useUnit(changeUserDataEv)
	const setNextStep = useUnit(setNextStepEv)
	const currentPage = useUnit($currentPage)


	const startVideo = () => {
		navigator.mediaDevices.getUserMedia({video: true})
			.then((currentStream) => {
				videoRef.current.srcObject = currentStream
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const takePicture = () => {
		setAnimation(true)

		const canvas = document.createElement('canvas')
		canvas.width = videoRef.current.videoWidth
		canvas.height = videoRef.current.videoHeight
		canvas.getContext('2d').drawImage(videoRef.current, 0, 0)
    debugger
		changeUserData({
			field: 'images',
			value: canvas.toDataURL()
		})

		setTimeout(() => {setNextStep()}, 1000)


		// canvas.toBlob(function(blob){URL.createObjectURL(blob)
		// 	changeUserData({
		// 		field: 'image',
		// 		value: URL.createObjectURL(blob)
		// 	})
		// 	setTimeout(() => {
		// 		sendRegistration()
		// 	}, 250)
		// },'image/png');
	}

	useEffect(() => {
		startVideo()
		videoRef.current.setAttribute('autoplay', '');
		videoRef.current.setAttribute('muted', '');
		videoRef.current.setAttribute('playsinline', '')

		videoRef.current.addEventListener('canplay', () => {

			const width = window.innerWidth
			const height = window.innerHeight - 136

			canvasRef.current.width = window.innerWidth
			canvasRef.current.height = window.innerHeight

			return setInterval(() => {
				if(canvasRef.current){
					canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, width, height)
				}
			})
		})
	}, []);

	return <>
		<div className={style.facescan_wrapper}></div>
		<div onAnimationEnd={() => setAnimation(false)}
				 className={[style.facescan_flash, animation ? style.start_flash : ''].join(' ')}></div>
		<video className={style.facescan_video} crossOrigin={"anonymous"} ref={videoRef} autoPlay></video>
		<canvas className={style.facescan_canvas} ref={canvasRef}/>
		<div className={style.facescan_button_wrapper}>
			<Button className={style.next_button} onClick={takePicture}>Сделать фото</Button>
			<Button className={style.back_button} onClick={() => setNextStep(currentPage - 1)}>
				Назад
			</Button>
		</div>
	</>
}
