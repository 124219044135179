import style from './HReventlanding.module.css'
import {Button} from "antd";
import star from '../../images/star.png'
import { CloseOutlined } from '@ant-design/icons';
import {toggleShowSuccessEv} from "../../models/landingModel";
import {useUnit} from "effector-react";

export default function RegSuccess(){

	const toggleShowSuccess = useUnit(toggleShowSuccessEv)

	return <div className={style.reg_success_wrapper}>
		<div className={style.success_box}>
			<Button
				icon={<CloseOutlined />}
				className={style.success_close_button}
				onClick={toggleShowSuccess}
			/>
			<img src={star}/>
			<div className={style.success_title}>
				Ваша заявка отправлена
			</div>
			<div className={style.success_subtitle}>
				Наш менеджер свяжется с Вами
				в ближайшее время
			</div>
		</div>
	</div>
}
