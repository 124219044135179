import '../../App.css';
import * as React from 'react';
import style from './HReventlanding.module.css'
import Promo from "./Promo";
import Features from "./Features";
import Speakers from "./Speakers";
import Program from "./Program";
import Organizers from "./Organizers";
import Footer from "./Footer";
import RegForm from "./RegForm";
import RegSuccess from "./RegSuccess";
import {useUnit} from "effector-react";
import {$showSuccess} from "../../models/landingModel";

function Landing() {
  const isPhone = window.outerWidth < 1200
  const showSuccess = useUnit($showSuccess)
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Promo isPhone={isPhone}/>
        <Features isPhone={isPhone}/>
        <Speakers isPhone={isPhone}/>
        <Program isPhone={isPhone}/>
        <RegForm isPhone={isPhone}/>
        <Organizers isPhone={isPhone}/>
      </div>
      <Footer isPhone={isPhone}/>
      {
        showSuccess && <RegSuccess />
      }
    </div>

  );
};

export default Landing
