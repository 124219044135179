import style from './Registration.module.css'
import CustomSlider from "./CustomSlider";

export default function InfoBlock(props) {

	const { items, activePoint, showProgressBar } = props

	return <div className={style.info_block}>
		<div className={style.info_title}>2 шага стать почётным гостем</div>
		<ol style={{
			paddingLeft: '14px',

			margin: showProgressBar ? '14px 0px 20px 0px' : '14px 0px 0 0px'
		}}>
			{
				items.map(item => <li key={`info-point-${item}`} className={style.info_point}>{item}</li>)
			}
		</ol>
    {showProgressBar ? <CustomSlider
      points={[
        {
          tooltip: '10%',
          id: 0
        },
        {
          tooltip: '20%',
          id: 1
        }
      ]}
      activePoint={activePoint}
    /> : ''}
	</div>
}
