import style from './HReventlanding.module.css'
import * as React from "react";
import locaiton from '../../images/location.png'
import phone from '../../images/phone.png'

export default function Footer(){
	return (
		<div className={style.footer_wrapper}>
			<div className={style.container}>
				<div className={[style.whiteTitle, style.footer_title].join(' ')} >
					Контакты
				</div>
				<div className={style.contacts_wrapper}>
					<div className={style.contacts_item}>
						<img src={locaiton}/>
						<div className={style.contacts_text}>ул. Сатпаева 22/1</div>
					</div>
					<div className={style.contacts_item}>
						<img src={phone}/>
						<div className={style.contacts_text}>+7 777 567 54 45</div>
					</div>
				</div>
			</div>
		</div>
	)
}
