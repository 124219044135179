import style from './OldLandingFoqus.module.css'
import Promo from "./Sections/Promo";
import KeyMetrics from "./Sections/KeyMetrics";
import Marketing from "./Sections/Marketing";
import HowItWorks from "./Sections/HowItWorks";
import Advantages from "./Sections/Advantages";
import Footer from "./Sections/Footer";
import dots_bg from "../../images/dots_bg.png";
export default function OldLandingFoqus(){
  const isPhone = window.outerWidth < 1200
  return <div className={style.langingfoqus_wrapper}>
    <div className={style.container}>
      <Promo/>
      <KeyMetrics/>
      <Marketing/>
      <HowItWorks isPhone={isPhone}/>
      <Advantages/>
      <Footer/>
    </div>
    <img src={dots_bg} className={style.dots_bg}/>
  </div>
}
