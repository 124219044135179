import React from 'react';
import style from "../LandingFoqus.module.css";
import {blogData} from "../../../data/blogData";
import BlogPostItem from "./BlogPostItem";
import blog_splat_2 from "../../../images/blogImages/blog_splat_2.png";
import mobile_blog_splat_2 from '../../../images/newLangingSplats/mobile_blog_splat_2.png'

function BlogPosts({isPhone}) {
	return (
		<div className={style.blog_posts}>
			<div className={style.container}>
				<div className={style.blog_posts_wrapper}>
					{
						blogData.slice(1).map((item, index) => <BlogPostItem key={`post-${index}-${item.id}`}
																																 isPhone={isPhone}{...item}/>)
					}
				</div>
			</div>
			<img src={blog_splat_2} id={'blog_splat_2'}/>
			<img src={mobile_blog_splat_2} id={'mobile_blog_splat_2'}/>
		</div>
	);
}

export default BlogPosts;
