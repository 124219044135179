const questions_dictionary = {
  1: 'Я склонен сочувствовать другим людям',
  2: 'У меня полно разных идей',
  3: 'Обычно я интересуюсь делами коллег и друзей',
  4: 'Я часто чувствую себя поглощенным разными проблемами',
  5: 'Я легко справляюсь с ситуациями, которые требуют взаимодействия с другими людьми',
  6: 'Я довольно скрытный человек',
  7: 'Я стараюсь избегать общения со сложными людьми',
  8: 'Мне нравится разбираться в сложных текстах и задачах',
  9: 'Я часто попусту трачу свое время',
  10: 'Меня легко заставить беспокоиться',
  11: 'Я планирую и придерживаюсь этих планов',
  12: 'Меня довольно легко задеть',
  13: 'Я легко завожу друзей',
  14: 'Как правило я работаю по расписанию',
  15: 'Я умею успокаивать людей',
}

const answers_dictionary = {
  1: 'Совершенно неверно',
  2: 'Скорее неверно',
  3: 'Сложно сказать',
  4: 'Скорее верно',
  5: 'Совершенно верно',
}

const reverse_answers = [6, 9]

const categories = {
  agreeableness: [1, 3, 7, 15],
  openness: [2, 8],
  extraversion: [5, 6, 13],
  conscientiousness: [11, 14, 9],
  neuroticism: [4, 10, 12]
}

export { questions_dictionary, answers_dictionary, reverse_answers, categories }
