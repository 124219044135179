import React from 'react';
import style from "../LandingFoqus.module.css";
import {blogData} from "../../../data/blogData";
import {useParams} from "react-router-dom";
import tdesign_time from '../../../images/blogImages/tdesign_time.svg'
import quotes from '../../../images/blogImages/quotes.svg'
import {Col} from "antd";
import BlogPostItem from "./BlogPostItem";
import {useUnit} from "effector-react";
import {togglePostPopupEv, toggleShowMenuEv} from "../../../models/newLandingModel";
import logo from "../../../images/Foqus_white.png";
import {MenuOutlined} from "@ant-design/icons";
import post_splat_1 from '../../../images/blogImages/post_splat_1.png'
import post_splat_2 from '../../../images/blogImages/post_splat_2.png'
import mobile_post_splat_1 from '../../../images/newLangingSplats/mobile_post_splat_1.png'
import mobile_post_splat_2 from '../../../images/newLangingSplats/mobile_post_splat_2.png'
import BlogPopup from "./BlogPopup";
import ResponsePopup from "../sections/ResponsePopup";

function PostPage({isPhone, setTab}) {
	const {id} = useParams()
	const toggleShowMenu = useUnit(toggleShowMenuEv)
	const {
		title,
		promoImg,
		timeToRead,
		date,
		content_1,
		content_img,
		content_2,
		author,
		quote,
	} = blogData.find(item => item.id === +id)

	const relatedPosts = blogData.filter(item => item.id !== +id)
	const togglePostPopup = useUnit(togglePostPopupEv)

	return (
		<div className={style.post_page_wrapper}>
			<BlogPopup />
			<ResponsePopup />
			<div className={style.container} >
					<div className={style.promo_header_wrapper}>
					<img src={logo} className={style.promo_logo} onClick={() => setTab('/')}/>
					<MenuOutlined className={style.promo_hamburger} onClick={toggleShowMenu}/>
				</div>
				<div className={style.post_promo}>
					<div>
						<div className={style.post_title}>{title}</div>
						<div className={style.post_subtitle}>
							<div className={style.post_time}><img src={tdesign_time} />{timeToRead} min to read</div>
							<div className={style.post_date}>{date}</div>
						</div>
					</div>
					<img src={promoImg}/>
				</div>
				<Col span={isPhone ? 24 : 16}>
					<div className={style.post_text} dangerouslySetInnerHTML={{__html: content_1}}/>
					<img src={content_img} className={style.post_content_img}/>
					<div className={style.post_text} dangerouslySetInnerHTML={{__html: content_2}}/>
					<div className={style.post_footer}>
						<div className={style.post_author}>
							<img src={author.img} />
							<div>
								<div className={style.post_author_name}>By <a href={author.linkedin} target={'_blank'} className={style.name_link}>{author.name}</a></div>
								<div className={style.post_author_position}>{author.position}</div>
							</div>
						</div>
						{
							!isPhone ?
								<>
									<div className={style.post_footer_divider}/>
									<img src={quotes}/>
									<div className={style.post_quote} dangerouslySetInnerHTML={{__html: `${quote}`}}/>
								</>
								:
								''
						}
					</div>
					<div className={style.post_button} onClick={togglePostPopup}>
						Book a demo and see how we can help →
					</div>
					<div className={style.related_post}>
						<div className={style.related_post_title}>
							Related post
						</div>
						<div className={style.related_post_posts}>
							{relatedPosts.map(item => <BlogPostItem key={item.id} {...item}/>)}
						</div>
					</div>
				</Col>
			</div>
			<img src={post_splat_1} id={'post_splat_1'}/>
			<img src={post_splat_2} id={'post_splat_2'}/>
			<img src={mobile_post_splat_1} id={'mobile_post_splat_1'}/>
			<img src={mobile_post_splat_2} id={'mobile_post_splat_2'}/>
		</div>
	);
}

export default PostPage;
