import {createEffect} from "effector/compat";

export const sendRequestDemoFx = createEffect()
	.use(async (payload) => {
		try{
			const response = await fetch(
				'https://ikwu596k35.execute-api.eu-west-1.amazonaws.com/Phase1/DemoRequestsGS',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(payload)
				}
			)

			return response.json()
		} catch (e){
			console.error(e)
		}
	})
