import style from "./HReventlanding.module.css";
import cup from "../../images/cup.png";
import chart from "../../images/chart.png";
import circle from "../../images/circle.png";
import * as React from "react";

export default function Features(){
	return (
		<div className={style.features_wrapper}>
			<div className={style.whiteTitle}>
				Что вас ждет
			</div>

			<div className={style.features_card_wrapper}>
				<div className={style.customCard}>
					<div className={style.cardImgBox}>
						<img src={cup} className={style.card_img}/>
					</div>
					<div className={style.cardTextBox}>
						Расскажем, как поведенческий аудит может помочь вашей компании создать продуктивную рабочую среду
					</div>
				</div>
				<div className={style.customCard}>
					<div className={style.cardImgBox}>
						<img src={chart} className={style.card_img}/>
					</div>
					<div className={style.cardTextBox}>
						Ознакомитесь с последними<br/> статистическими данными<br/> и тенденциями, которые помогут<br/> вам привлечь
						лучших<br/> представителей нового поколения
					</div>
				</div>
				<div className={style.customCard}>
					<div className={style.cardImgBox}>
						<img src={circle} className={style.card_img}/>
					</div>
					<div className={style.cardTextBox}>
						Узнаете о передовых HR<br/>-инициативах от одной из самых<br/> инновационных компаний
						мира,<br/> представитель
						Uber расскажет «внутреннюю кухню» в Big Tech
					</div>
				</div>
			</div>
		</div>
	)
}
