import style from './Registration.module.css'
import InfoBlock from "./InfoBlock";
import {Button, Checkbox, DatePicker, Form, Input, Select} from "antd";
import Icon from '@ant-design/icons'
import {$userData, changeUserDataEv, formIsValidEv, sendRegistrationDataEv} from "../../models/registrationModel";
import {useUnit} from "effector-react";
import locale from 'antd/es/date-picker/locale/ru_RU.js';
import NextButton from "./NextButton";
import {Link} from "@aws-amplify/ui-react";
import {useEffect, useRef} from "react";
import {logDOM} from '@testing-library/react';

const tags = [
	'Живая музыка',
	'Караоке',
	'DJ',
	'Танцы',
	'Открытый микрофон',
	'Кальян',
	'Стейк и вино',
	'Коктейли',
	'Высокая кухня',
	'Крафтовое пиво',
	'Вкусный кофе',
	'Праздничные мероприятия',
]

const selectOptions = tags.map(item => ({value: item, label: item}))

const CalendarSvg = () => {
	return <svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={20}
		fill="none"
	>
		<path
			stroke="#A5A7AD"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M15 1.667v1.666M5 1.667v1.666"
		/>
		<path
			stroke="#A5A7AD"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M9.996 10.833h.008m-.008 3.334h.008m3.322-3.334h.007m-6.666 0h.007m-.007 3.334h.007"
		/>
		<path
			stroke="#A5A7AD"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M2.917 6.667h14.166M2.083 10.203c0-3.631 0-5.447 1.044-6.575C4.17 2.5 5.85 2.5 9.208 2.5h1.584c3.358 0 5.038 0 6.081 1.128 1.044 1.128 1.044 2.944 1.044 6.575v.428c0 3.63 0 5.446-1.044 6.574-1.043 1.128-2.723 1.128-6.081 1.128H9.208c-3.358 0-5.038 0-6.081-1.128-1.044-1.128-1.044-2.943-1.044-6.574v-.428ZM2.5 6.667h15"
		/>
	</svg>

}

function CalendarIcon() {
	return <Icon
		component={CalendarSvg}
	/>
}

export default function FormPage(props){

	const { form } = props
	const userData = useUnit($userData)
	const changeUserData = useUnit(changeUserDataEv)
	const formIsValid = useUnit(formIsValidEv)
	const inputRef = useRef()

	const handleChange = (field, value) => {
		changeUserData({
			field: field,
			value: value
		})
	}

	useEffect(() => {
		form.setFieldValue('birthday', userData.birthday)
	}, [userData.birthday]);

	return <div className={style.form_page_wrapper}>
		<InfoBlock
			items={[
				'Заполните поля ниже для скидки в 10%',
				'Перейдите к Шагу 2 и получите 20%'
			]}
		/>
		<Form form={form} className={style.form_wrapper} onFinish={formIsValid}>
			<Form.Item
				name={'name'}
				style={{marginBottom: '12px'}}
				rules={[
					{
						required: true,
						message: 'Пожалуйста введите имя!',
					},
				]}
			>
				<Input className={style.form_input} placeholder={'Имя'} onChange={(e) => handleChange('name', e.target.value)}
							 value={userData.name}/>
			</Form.Item>
			<Form.Item
				name={'surname'}
				style={{marginBottom: '12px'}}
				rules={[
					{
						required: true,
						message: 'Пожалуйста введите фамилию!',
					},
				]}
			>
				<Input className={style.form_input} placeholder={'Фамилия'}
							 onChange={(e) => handleChange('surname', e.target.value)} value={userData.surname}/>
			</Form.Item>
			<Form.Item
				name={'phone'}
				style={{marginBottom: '12px'}}
				validateTrigger={'onBlur'}
				rules={[
					{
						required: true,
						message: 'Пожалуйста введите номер телефона!',
					},
					({getFieldValue}) => ({
						validator(_, value ){
							if(/(8|7)7\d{2}\d{7}/g.test(value)){
								return Promise.resolve()
							}
							return Promise.reject(new Error('Неверный номер телефона'));
						}
					})
				]}
			>
				<Input type={'number'} className={style.form_input} placeholder={'Телефон'}
							 onChange={(e) => handleChange('phone', e.target.value)} value={userData.phone} />
			</Form.Item>
			{/*<div style={{position: 'relative'}}>*/}
				{/*<Input type={'date'} ref={inputRef} onChange={(event) => handleChange('birthday', event.target.value)}/>*/}
				<Form.Item
          className={'inputWithBirtday'}
          style={{
            maxWidth: '100%',
            minWidth: '100%'
          }}
					name={'birthday'}
					// style={{marginBottom: '12px', position: 'absolute', top: 0, width: '100%'}}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите дату рождения!',
						},
					]}
				>
					<Input
						className={style.form_input_birthday}
            ref={inputRef}
            type="date"
						placeholder='YYYY-MM-DD'
						value={userData.birthday}
						onChange={(event) => handleChange('birthday', event.target.value)}
						onTouchEnd={() => {
							setTimeout(() => {
								if(navigator.userAgent.includes('Macintosh') || navigator.userAgent.includes('IPhone')) {
									inputRef.current.input.focus();
								} else {
									inputRef.current.input.showPicker();
								}
							}, 100);
						}}
					/>
          { (navigator.userAgent.includes('Mac OS') || navigator.userAgent.includes('IPhone')) ?

            userData.birthday == '29-12-1990' ?
            <span style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              fontSize: '16px',
              color: '#bfbfbf',
              pointerEvents: 'none',
            }}>{'Дата рождения'}</span>
          :
            ''
          :
          ''
        }

					{/*<DatePicker locale={locale} className={style.form_input} placeholder={'Дата рождения'}*/}
					{/*						suffixIcon={<CalendarIcon/>} onChange={(date) => handleChange('birthday', date)}*/}
					{/*						value={userData.birthday}/>*/}
				</Form.Item>
			{/*</div>*/}
      <div className={style.form_text}>Сделаем приятный сюрприз <span style={{fontSize: '30px', padding: '0 5px'}} role="img" aria-label="tada">🎉</span> для вашего
        особого дня
      </div>
      {/*<div>{navigator.userAgent}</div>*/}
			<Form.Item
				style={{
					marginBottom: 0,
				}}
				name={'tags'}
				rules={[
					{
						validator: async () => {
							if (userData.tags.length === 0) {
								return Promise.reject(
									new Error("Пожалуйста выберите ваши интересы!"),
								);
							}
						},
					},
				]}
			>
				<div className={style.interests_wrapper}>
					<div className={style.interests_title}>Ваши интересы</div>
					{/*<div className={style.interests_subtitle}>Выберите теги ваших интересов. Так мы поймём что лучше вам*/}
					{/*	предложить*/}
					{/*</div>*/}
					<div className={style.tags_wrapper}>
						{
							tags.map(item => {
								return <div key={`tag-${item}`}
														className={[style.interests_tag, userData.tags.includes(item) ? style.tag_active : ''].join(' ')}
														onClick={() => handleChange('tags', item)}>{item}</div>
							})
						}
					</div>
				</div>
			</Form.Item>
			<Form.Item
				name={'isAgreement'}
				valuePropName="checked"
				style={{
					textAlign: 'left'
				}}
				rules={[
					{
						validator: async () => {
							if (userData.isAgreement == false) {
								return Promise.reject(
									new Error("Пожалуйста подтвердите согласие!"),
								);
							}
						},
					},
				]}
			>
				<Checkbox className={style.form_checkbox} defaultChecked={userData.isAgreement}
									onChange={() => handleChange('isAgreement', !userData.isAgreement)}>
					Я согласен на обработку <Link href={'/terms'} style={{color: '#005FF9'}}>данных</Link>
				</Checkbox>
			</Form.Item>
		</Form>
	</div>
}
