import {createStore} from "effector";

const initialState = {
	name: '',
	surname: '',
	phone: '',
	birthday: '29-12-1990',
	tags: [],
	isAgreement: true,
	images: [],
}

export const $currentPage = createStore(0)
$currentPage.watch(state => console.log('$currentPage', state))

export const $userData = createStore(initialState)
$userData.watch(state => console.log('$userData', state))

export const $formClickedButton = createStore(0)
$formClickedButton.watch(state => console.log('formClickedButton', state))
