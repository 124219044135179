import style from "./Registration.module.css";
import {Button} from "antd";
import {sendRegistrationDataEv, setFormClickedButtonEv} from "../../models/hreventModel";
import {useUnit} from "effector-react";

export default function NextButton(props) {

	const { currentPage, onClick, title, form } = props

	const triggerFormSubmit = (button) => {
		form.submit()
	}


	return <div className={style.next_button_wrapper}>
		<Button className={style.next_button} onClick={currentPage === 0 ? () => triggerFormSubmit(2) : onClick}>
			{title}
		</Button>
	</div>
}
