import style from '../OldLandingFoqus.module.css'
export default function Advantages(){
	return <div className={style.advantages_wrapper}>
		<div className={style.advantages_title}>
			ТЕХНИЧЕСКИЕ ПРЕИМУЩЕСТВА
		</div>
		<div className={style.advantages_cards_wrapper}>
			<div className={style.advantages_card}>
				<div className={style.advantages_card_title}>
					HUMAN-CENTRIC AI
				</div>
				<div className={style.advantages_card_desc}>
					Собственные уникальные разработки FOQUS в области ИИ и компьютерного
					зрения, а также используем сторонние научные модели поведения
				</div>
			</div>
			<div className={style.advantages_card}>
				<div className={style.advantages_card_title}>
					BIG DATA
				</div>
				<div className={style.advantages_card_desc}>
					Экспертная работа с данными позволяет нам выявлять скрытые закономерности, определять тенденции и
					предоставлять информацию в реальном времени
				</div>
			</div>
			<div className={style.advantages_card}>
				<div className={style.advantages_card_title}>
					GRAPH NETWORK
				</div>
				<div className={style.advantages_card_desc}>
					Моделируем сложные взаимосвязи и зависимости с помощью технологии Graph Network, обеспечивая беспрецедентное
					понимание экосистемы данных
				</div>
			</div>
		</div>
	</div>
}
