import {useLayoutEffect, useState} from "react";
window.lastScrollY = 0

export const useScrollOffset = () => {
	const [offset, setOffset] = useState(0);
	const [down, setDown] = useState(true)

	useLayoutEffect(() => {
		const onScroll = () => {
			setOffset(window.scrollY)
			let currentOffset = window.scrollY
			if(currentOffset > window.lastScrollY){
				setDown(true)
				window.lastScrollY = currentOffset <= 0 ? 0 : currentOffset - 1;
			} else if(currentOffset < window.lastScrollY){
				setDown(false)
				window.lastScrollY = currentOffset <= 0 ? 0 : currentOffset + 1;
			}
		};
		// clean up code
		// window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	return [offset, down]
}
