import {createEffect} from "effector";
import AWS from 'aws-sdk'
import dayjs from "dayjs";

export const sendHREventFx = createEffect().use(async (payload) => {
	try {
		const response = await fetch(
			'https://6lga65wdw5.execute-api.eu-west-1.amazonaws.com/HREvent1/UserRecords',

			{
				method: 'POST',
				body: JSON.stringify(payload)
			}
		)

		return await response.json()
	} catch (e) {
		console.error(e)
	}
})

export const saveToS3Fx = createEffect()
	.use(async ({tags, file}) => {
			const s3 = new AWS.S3({
			accessKeyId: 'AKIAV7QO7JUKGJF3OBFM',
			secretAccessKey: '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',
			region: 'eu-west-1'
		});

		const tagString = Object.entries(tags)
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join('&');

		const params = {
			Bucket: 'prod-foqus-demo-s3',
			Key: `data-stream/demo/2024-08-21/face-video-onboarding/${file.name}.mp4`,
			Body: file,
			ContentType: file.type,
			Tagging: tagString,
		};

		s3.upload(params, (err, data) => {
			if (err) {
				console.log('Ошибка при загрузке:', err);
				return  false
			} else {
				console.log('Успешно загружено:', data.Location);
				return  true
			}
		});
	})

export const sendUserDataFx = createEffect()
	.use(async (payload) => {
		try {
			const response = await fetch(
				'https://6b84ax7cr8.execute-api.eu-west-1.amazonaws.com/Demo/SingleOnboarding',
				{
					method: 'POST',
					body: JSON.stringify(payload)
				}
			)

			return await response.json()
		} catch (e) {
			console.error(e)
		}
	})
