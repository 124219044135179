import style from '../OldLandingFoqus.module.css'
import footer_blob from '../../../images/footer_blob.png'
import footer_logo from '../../../images/footer_logo.png'
export default function Footer(){
	return <div className={style.footer_wrapper}>
		<div className={style.footer_logo_wrapper}>
			<img src={footer_logo} style={{zIndex: 2, position: 'relative'}}/>
			<img src={footer_blob} className={style.logo_blob}/>
		</div>
		<div id={'contacts'} className={style.footer_title}>
			Контакты
		</div>
		<div className={style.footer_contact}>
			ул. Сатпаева 22/5, офис 202
		</div>
		<div className={style.footer_contact}>
			y.zapiyev@foqus.kz
		</div>
		<div className={style.footer_contact}>
			+7 771 863 77 77
		</div>
	</div>
}
