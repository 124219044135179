import {useState} from "react";
import style from './Registration.module.css'
import StartPage from "./StartPage";
import FormPage from "./FormPage";
import NextButton from "./NextButton";
import InfoPage from "./InfoPage";
import FaceScanPage from "./FaceScanPage";
import SuccessPage from "./SuccessPage";
import {Form, Spin} from "antd";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import {useUnit} from "effector-react";
import {$currentPage, sendRegistrationFx, setNextStepEv} from "../../models/registrationModel";

const button_titles = {
	1: 'Получить 20%',
	2: 'Получить 20%',
}

export default function Registration(){
	const currentPage = useUnit($currentPage)
	const setNextStep = useUnit(setNextStepEv)
	const loading = useUnit(sendRegistrationFx.pending)
	const [form] = Form.useForm()

	const content = {
		0: <StartPage toNextPage={setNextStep}/>,
		1: <FormPage form={form}/>,
		2: <InfoPage />,
		3: <FaceScanPage />,
		4: <SuccessPage />
	}

	return <Spin
    style={{
      padding: '10px',
    }}
    spinning={loading}
    indicator={<Loading3QuartersOutlined spin/>}
    size={'large'}
  >

		<div className={style.registration_wrapper}>
			{
				content[currentPage]
			}
			{
				currentPage > 0 && currentPage < 3 ?
					<NextButton currentPage={currentPage} onClick={setNextStep} title={button_titles[currentPage]} form={form}/>
					:
					''
			}
		</div>
	</Spin>
}
