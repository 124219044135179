import style from './TestManager.module.css'
import {useEffect, useState} from "react";
import {Button, Radio} from "antd";
export default function TestManager(props) {

	const {questions, answers, onFinish, onExit} = props

	const [currentQs, setCurrentQs] = useState(1)

	const [chosenAnswers, setChosenAnswers] = useState({})

	const onBack = () => {
		if(currentQs === 1) return
		const answersCopy = {...chosenAnswers}
		delete answersCopy[currentQs]
		setChosenAnswers(answersCopy)
		setCurrentQs(currentQs - 1)
	}

	const onNext = () => {
		if(currentQs === Object.keys(questions).length){
			onFinish(chosenAnswers)
		} else {
			setCurrentQs(currentQs + 1)
		}
	}

	const onAnswerClick = (e) => {
		setChosenAnswers({
			...chosenAnswers,
			[currentQs]: e.target.value
		})
	}

	return <>
		<Button className={style.exit_button} onClick={onExit}>Выйти</Button>
		<div className={style.counter}>
			{currentQs}/{Object.keys(questions).length}
		</div>
		<div className={style.content_wrapper}>
			<div className={style.question}>
				{
					questions[currentQs]
				}
			</div>
			<div>
				<Radio.Group
					className={style.answers_wrapper}
					onChange={onAnswerClick}
					value={chosenAnswers[currentQs]}
				>
					<div className={style.answers_title}>Выберите один вариант</div>
					{
						Object.keys(answers).map(item => {
							return <Radio key={`answer-${item}`} value={item} className={[style.answer_item, chosenAnswers[currentQs] === item ? style.answer_active : ''].join(' ')}>{answers[item]}</Radio>
						})
					}
				</Radio.Group>
				<div className={style.buttons_wrapper}>
					<Button className={[style.button, style.back_button].join(' ')} onClick={onBack} disabled={currentQs === 1}>
						Назад
					</Button>
					<Button type={'primary'} className={style.button} onClick={onNext} disabled={!chosenAnswers[currentQs]}>
						{
							currentQs === Object.keys(questions).length ?
								'Завершить тест'
								:
								'Далее'
						}
					</Button>
				</div>
			</div>
		</div>
	</>
}
