import {createEffect} from "effector";

export const sendLandingRegistrationFx = createEffect().use(async (payload) => {
	try {
		const response = await fetch(
			'https://5x7kl8ow1c.execute-api.eu-west-1.amazonaws.com/Phase1/HRTechEvent',
			{
				method: 'POST',
				body: JSON.stringify(payload)
			}
		)
//
		return await response.json()
	} catch (e) {
		console.error(e)
	}
})
