import style from "./HReventlanding.module.css";
import {Form, Input} from "antd";
import * as React from "react";
import {useForm} from "antd/es/form/Form";
import {useUnit} from "effector-react";
import {$showSuccess, sendLandingRegistrationFx} from "../../models/landingModel";
import {useEffect} from "react";
import { LoadingOutlined } from '@ant-design/icons';


export default function RegForm(props){
	const [form] = useForm()

	const sendRegData = useUnit(sendLandingRegistrationFx)
	const loading = useUnit(sendLandingRegistrationFx.pending)

	const showSuccess = useUnit($showSuccess)

	useEffect(() => {
		if(showSuccess){
			form.resetFields()
		}
	}, [showSuccess]);

	return (
		<div className={style.regform_wrapper} id={'regform'}>
			<Form form={form} className={style.form_wrapper} onFinish={sendRegData}>
				<div className={style.whiteTitle} style={{marginBottom: '50px'}}>
					Регистрация
				</div>
				<Form.Item
					name={'Surname'}
					style={{marginBottom: '12px'}}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите фамилию!',
						},
					]}
				>
					<Input className={style.form_input} placeholder={'Фамилия'}/>
				</Form.Item>
				<Form.Item
					name={'Name'}
					style={{marginBottom: '12px'}}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите имя!',
						},
					]}
				>
					<Input className={style.form_input} placeholder={'Имя'} />
				</Form.Item>
				<Form.Item
					name={'Mobile'}
					style={{marginBottom: '12px'}}
					validateTrigger={'onBlur'}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите номер телефона!',
						},
						({getFieldValue}) => ({
							validator(_, value) {
								if (/(8|7)7\d{2}\d{7}/g.test(value)) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('Неверный номер телефона'));
							}
						})
					]}
				>
					<Input type={'number'} className={style.form_input} placeholder={'Телефон'}/>
				</Form.Item>
				<Form.Item
					name={'Email'}
					style={{marginBottom: '12px'}}
					validateTrigger={'onBlur'}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите email!',
						},
						({getFieldValue}) => ({
							validator(_, value) {
								if (/.+@.+\..+/g.test(value)) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('Неверный email'));
							}
						})
					]}
				>
					<Input className={style.form_input} placeholder={'Email'}/>
				</Form.Item>
				{/*<div style={{position: 'relative'}}>*/}
				{/*<Input type={'date'} ref={inputRef} onChange={(event) => handleChange('birthday', event.target.value)}/>*/}
				<Form.Item
					name={'Company'}
					style={{marginBottom: '12px'}}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите место работы!',
						},
					]}
				>
					<Input className={style.form_input} placeholder={'Место работы'}/>
				</Form.Item>
				<Form.Item
					name={'Occupation'}
					style={{marginBottom: '12px'}}
					rules={[
						{
							required: true,
							message: 'Пожалуйста введите должность!',
						},
					]}
				>
					<Input className={style.form_input} placeholder={'Должность'}/>
				</Form.Item>
				{/*</div>*/}
				{/*<div>{navigator.userAgent}</div>*/}
				<div className={style.form_buttons_wrapper}>
					<Form.Item>
						<div onClick={form.submit} className={[
							style.buttonRegistration,
							style.loading_button,
							loading ?
								style.loading_button
								:
								''
						].join(' ')}>
							{
								loading ?
									<LoadingOutlined style={{marginRight: '10px'}}/>
									:
									''
							}
							Зарегистрироваться
						</div>
					</Form.Item>
					<div className={style.form_buttons_separator}>или</div>
					<a href={'https://t.me/HRTechAssistantBot'} target={'_blank'} className={style.buttonTelegramRegistration}>Регистрация
						в телеграмм боте</a>
				</div>
			</Form>
		</div>
	)
}
