import style from './Registration.module.css'
import FormPage from "../HrEvent/FormPage";
import NextButton from "../HrEvent/NextButton";
import InfoPage from "../HrEvent/InfoPage";
import FaceScanPage from "../HrEvent/FaceScanPage";
import SuccessPage from "../HrEvent/SuccessPage";
import {Form, Spin} from "antd";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import {useUnit} from "effector-react";
import {$currentPage, finishHREventEv, sendHREventFx, setNextStepEv} from "../../models/hreventModel";
import TestManager from "../../components/TestManager/TestManager";
import {questions_dictionary, answers_dictionary} from "./questions_dictionary";

const button_titles = {
  0: 'Далее',
  1: 'Приступить',
}

export default function HrEvent(){
  const currentPage = useUnit($currentPage)
  const setNextStep = useUnit(setNextStepEv)
  const loading = useUnit(sendHREventFx.pending)
  const finishHREvent = useUnit(finishHREventEv)
  const [form] = Form.useForm()

  const content = {
    // 0: <StartPage toNextPage={setNextStep}/>,
    0: <FormPage form={form}/>,
    1: <InfoPage />,
    2: <FaceScanPage />,
    3: <TestManager
      questions={questions_dictionary}
      answers={answers_dictionary}
      onFinish={finishHREvent}
      onExit={() => setNextStep(0)}
    />,
    4: <SuccessPage />
  }

  return <Spin
    style={{
      padding: '10px',
    }}
    spinning={loading}
    indicator={<Loading3QuartersOutlined spin/>}
    size={'large'}
  >

    <div className={style.registration_wrapper}>
      {
        content[currentPage]
      }
      {
        currentPage < 2 ?
          <NextButton currentPage={currentPage} onClick={setNextStep} title={button_titles[currentPage]} form={form}/>
          :
          ''
      }
    </div>
  </Spin>
}
