import style from "../LandingFoqus.module.css";
import logo from "../../../images/Foqus_white.png";
import header_icon from '../../../images/header_icon.svg'
import login_arrow from '../../../images/login_arrow.svg'
import {Link} from '@aws-amplify/ui-react';
import {useUnit} from "effector-react";
import {$showMenu, toggleShowMenuEv} from "../../../models/newLandingModel";
import {useLocation} from "react-router-dom";
import {useScrollOffset} from "../../../hooks/useScrollOffset";

export default function Header({setTab, isPhone}) {

  const showMenu = useUnit($showMenu)
  const toggleShowMenu = useUnit(toggleShowMenuEv)
  const {pathname} = useLocation()

  const offset = useScrollOffset()


  return <>
    <div className={[style.header_outclick_overlay, showMenu ? style.showOverlay : style.hideOverlay].join(' ')} onClick={toggleShowMenu}/>
    <header className={[style.header, showMenu ? style.showMenu : style.hideMenu].join(' ')}>
      <div className={style.container} style={{height: '100%', width: isPhone ? '100%' : '90rem'}}>
        <div className={style.header_wrapper}>
          <img onClick={() => setTab('/')} src={logo} className={style.header_foqus_logo}/>
          <ul className={style.header_menu_wrapper}>
            <li className={[style.header_menu_item, pathname === '/about_us' ? style.header_active_item : ''].join(' ')}>About us</li>
            <a href={'#contacts'} className={[style.header_menu_item, pathname === '/contacts' ? style.header_active_item : ''].join(' ')}>Contacts</a>
            <li onClick={() => setTab('/blog')} className={[style.header_menu_item, /\/blog/g.test(pathname) ? style.header_active_item : ''].join(' ')}>Blog</li>
          </ul>
          <div className={style.header_login_wrapper}>
            <Link target={'_blank'} href={'https://team-iw18119.slack.com/team/U050SG2ET55'}>
              <img src={header_icon} className={style.header_icon}/>
            </Link>
            <a target={'_blank'} href={'https://foqus-portal.live'} className={style.login_button}>
              Log in
              <img src={login_arrow} style={{marginLeft: '10px'}} className={style.login_arrow}/>
            </a>
          </div>
        </div>
      </div>
    </header>
  </>
}
