import style from '../OldLandingFoqus.module.css'
import metric_1 from '../../../images/metric_1.png'
import metric_2 from '../../../images/metric_2.png'
import metric_3 from '../../../images/metric_3.png'
export default function KeyMetrics(){
	return <div className={style.keyMetrics_wrapper}>
		<div className={style.keyMetrics_title}>
			ВЫЯВЛЯЕМ КЛЮЧЕВЫЕ ПОКАЗАТЕЛИ
		</div>
		<div className={style.keyMetrics_subtitle}>
			ДЛЯ HR:
		</div>
		<div className={style.keyMetrics_desc}>
			Внедряйте поведенческий аудит для эффективного управления HR<br/>менеджмента
		</div>
		<div className={style.keyMetrics_cards_wrapper}>
			<div className={style.keyMetrics_card}>
				<div className={style.keyMetrics_card_title}>КОМАНДНАЯ ДИНАМИКА</div>
				<div className={style.keyMetrics_card_desc}>Статистика количества опозданий, ранних уходов и перемещения в
					течение дня
				</div>
				<img style={{
					marginTop: '62px',
					width: '100%'
				}} src={metric_1}/>
			</div>
			<div className={style.keyMetrics_card}>
				<div className={style.keyMetrics_card_title}>ИНДЕКС<br/> СЧАСТЬЯ</div>
				<div className={style.keyMetrics_card_desc}>Анализ эмоционального состояния сотрудников для без-стрессного благополучия</div>
				<img style={{
					display: 'block',
					margin: '44px auto 0 auto'
				}} src={metric_2}/>
			</div>
			<div className={style.keyMetrics_card}>
				<div className={style.keyMetrics_card_title}>5-ФАКТОРНЫЕ МОДЕЛИ</div>
				<div className={style.keyMetrics_card_desc}>Опросник личности помогает определить функцию адаптации индивида в команду</div>
				<img style={{
					display: 'block',
					margin: '32px auto 0 auto'
				}} src={metric_3}/>
			</div>
		</div>
	</div>
}
