export const calculateValues = (answers, categories, reverse_answers, max_answers) => {
	const result = {}
	Object.keys(categories).forEach(category => {
		result[category] = Number((categories[category].reduce((acc, value) => {
			return acc + (reverse_answers.includes(value) ?  max_answers - parseInt(answers[value]) : parseInt(answers[value]))
		}, 0) / categories[category].length).toFixed(1))
	})

	return result
}
