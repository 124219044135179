import style from '../OldLandingFoqus.module.css'
import marketing_blob from '../../../images/marketing_blob.png'
import marketing_graph_1 from '../../../images/marketing_graph_1.png'
import marketing_graph_2 from '../../../images/marketing_graph_2.png'
import marketing_graph_3 from '../../../images/marketing_graph_3.png'
export default function Marketing(){
	return <div className={style.marketing_wrapper}>
		<div className={style.marketing_title}>
			ДЛЯ МАРКЕТИНГА:
		</div>
		<div className={style.marketing_subtitle}>
			Используйте глубокое профилирование клиентов с сегментацией для<br/>маркетинговых стратегий
		</div>
		<img src={marketing_blob} className={style.marketing_blob}/>
		<div className={style.marketing_cards_wrapper}>
			<div className={style.marketing_card}>
				<div className={style.marketing_card_title}>
					ДЕМОГРАФИКА
				</div>
				<ul className={style.marketing_card_desc_wrapper}>
					<li>
						уникальные посетители
					</li>
					<li>
						возраст, пол
					</li>
				</ul>
				<img src={marketing_graph_1} className={[style.marketing_graph_1, style.marketing_card_img].join(' ')}/>
			</div>
			<div className={style.marketing_card}>
				<div className={style.marketing_card_title}>
					ПСИХОГРАФИКА
				</div>
				<ul className={style.marketing_card_desc_wrapper}>
					<li>
						эмоции
					</li>
					<li>
						интересы
					</li>
				</ul>
				<img src={marketing_graph_2} className={[style.marketing_graph_2, style.marketing_card_img].join(' ')}/>
			</div>
			<div className={style.marketing_card}>
				<div className={style.marketing_card_title}>
					БИХЕВИОРИСТИКА
				</div>
				<ul className={style.marketing_card_desc_wrapper}>
					<li>
						лояльность
					</li>
					<li>
						взаимодействие
					</li>
				</ul>
				<img src={marketing_graph_3} className={[style.marketing_graph_3, style.marketing_card_img].join(' ')}/>
			</div>
		</div>
	</div>
}
