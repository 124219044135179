import React from 'react';
import style from "../LandingFoqus.module.css";
import {useNavigate} from "react-router-dom";

function BlogPostItem(props) {
	const { title, promoImg, date, id, isPhone }= props
	const navigate = useNavigate()

	const openPost = () => {
		navigate(`/blog/${id}`)
		window.scrollTo(0,0)
	}

	return (
		<div className={style.blog_post}>
			<div className={style.blog_post_img_wrapper}>
				<img src={promoImg} className={style.blog_post_img} onClick={openPost}/>
				<div className={style.blog_post_date} onClick={openPost}>{date}</div>
			</div>
			<div className={style.blog_post_title} onClick={openPost}>{title}</div>
			<div className={style.blog_post_button} style={{marginTop: isPhone ? 24 : 32}} onClick={openPost}>
				READ MORE →
			</div>
		</div>
	);
}

export default BlogPostItem;
