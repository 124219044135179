import React from 'react';
import {useUnit} from "effector-react";
import {$postPopup, sendRequestDemoEv, sendRequestDemoFx, togglePostPopupEv} from "../../../models/newLandingModel";
import style from '../LandingFoqus.module.css'
import slack from "../../../images/requestDemo/slack.svg";
import telegram from "../../../images/requestDemo/telegram.svg";
import whatsapp from "../../../images/requestDemo/whatsapp.svg";
import {Button, Form, Input} from "antd";
import {CloseOutlined} from "@ant-design/icons";

function BlogPopup() {
	const show = useUnit($postPopup)
	const togglePostPopup = useUnit(togglePostPopupEv)

	const sendRequestDemo = useUnit(sendRequestDemoEv)
	const loading = useUnit(sendRequestDemoFx.pending)

	return (
		<div className={style.post_popup_wrapper} style={{display: show ? 'block' : 'none'}}>
			<div className={style.post_popup_overlay} onClick={togglePostPopup}/>
			<div className={style.post_popup}>
				<div className={style.requestDemo_info}>
					<div>
						<div className={style.requestDemo_title}>
							Book <br/> a <div className={style.gradient_text} style={{display: 'inline-block'}}>Demo</div>
						</div>
						<div className={style.requestDemo_subtitle}>
							or contact us directly
						</div>
						<div className={style.requestDemo_links_wrapper}>
							<a href={'https://team-iw18119.slack.com/team/U050SG2ET55'} target={'_blank'}>
								<img src={slack}/>
							</a>
							<a href={'https://t.me/SerikJardem'} target={'_blank'}>
								<img src={telegram}/>
							</a>
							<a href={'https://wa.me/qr/WCHX3YAZ7YMBO1'} target={'_blank'}>
								<img src={whatsapp}/>
							</a>
						</div>
					</div>
					<div className={style.requestDemo_subdesc}>
						By clicking on "Book a demo" you agree<br/> to the right to process your data
					</div>
				</div>
				<div className={style.requestDemo_form}>
					<Form
						layout="vertical"
						onFinish={sendRequestDemo}

					>
						<Form.Item
							name={'first_value'}
							label={<label className={style.requestDemo_form_label}>Name</label>}
						>
							<div className={style.requestDemo_form_input_wrapper}>
								<Input className={style.requestDemo_form_input} placeholder={'Enter your name'}/>
							</div>
						</Form.Item>
						<Form.Item
							name={'second_value'}
							label={<label className={style.requestDemo_form_label}>Phone</label>}
						>
							<div className={style.requestDemo_form_input_wrapper}>
								<Input className={style.requestDemo_form_input} placeholder={'Enter your phone number'}/>
							</div>
						</Form.Item>
						<Form.Item
							name={'third_value'}
							label={<label className={style.requestDemo_form_label}>Email</label>}
						>
							<div className={style.requestDemo_form_input_wrapper}>
								<Input className={style.requestDemo_form_input} placeholder={'Enter your email'}/>
							</div>
						</Form.Item>
						<Form.Item
							name={'fourth_value'}
							label={<label className={style.requestDemo_form_label}>Industry</label>}
						>
							<div className={style.requestDemo_form_input_wrapper}>
								<Input className={style.requestDemo_form_input} placeholder={'Enter your industry or company'}/>
							</div>
						</Form.Item>
						<Form.Item
						>
							<Button type="primary" htmlType="submit" className={style.requestDemo_form_button} loading={loading}>
								Book a demo
							</Button>
						</Form.Item>
					</Form>
				</div>
				<CloseOutlined className={style.post_popup_close} onClick={togglePostPopup}/>
			</div>
		</div>
	);
}

export default BlogPopup;
