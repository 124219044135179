import style from '../LandingFoqus.module.css'
import {useUnit} from "effector-react";
import {$showPopup, resetShowPopupEv} from "../../../models/newLandingModel";
import {Button, Result} from "antd";
import success from '../../../images/success.svg'
import slack from "../../../images/requestDemo/slack.svg";
import telegram from "../../../images/requestDemo/telegram.svg";
import whatsapp from "../../../images/requestDemo/whatsapp.svg";
import {CloseOutlined} from "@ant-design/icons";
import React from "react";
export default function ResponsePopup(){

	const showPopup = useUnit($showPopup)
	// const showPopup = true
	const closePopup = useUnit(resetShowPopupEv)

	return <div className={style.response_popup} style={{display: showPopup ? 'flex' : 'none'}}>
		<div className={style.response_popup_overlay} onClick={closePopup}/>
		<div className={style.response_popup_card}>
			<img src={success}/>
			<div className={style.response_popup_title}>
				<div className={style.gradient_text} style={{display: 'inline-block'}}>Demo</div>
				Booked
			</div>
			<div className={style.response_popup_subtitle}>
				or contact us directly
			</div>
			<div className={style.requestDemo_links_wrapper}>
				<a href={'https://team-iw18119.slack.com/team/U050SG2ET55'} target={'_blank'}>
					<img src={slack}/>
				</a>
				<a href={'https://t.me/SerikJardem'} target={'_blank'}>
					<img src={telegram}/>
				</a>
				<a href={'https://wa.me/qr/WCHX3YAZ7YMBO1'} target={'_blank'}>
					<img src={whatsapp}/>
				</a>
			</div>
			<CloseOutlined className={style.post_popup_close} onClick={closePopup} style={{right: 28}}/>
		</div>
	</div>
}
