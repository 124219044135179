import style from '../OldLandingFoqus.module.css'
import logo from '../../../images/Foqus_white.png'
import arristol from '../../../images/arristol.png'
import promo_blob from '../../../images/promo_blob.png'

export default function Promo(){
	return <div className={style.promo_wrapper}>
		<img src={promo_blob} className={style.promo_blob}/>
		<img src={logo} className={style.promo_logo}/>
		<div className={style.promo_title}>
			Behavioral<br/>intelligenge
			<div className={style.promo_title_highlight}>at scale</div>
		</div>
		<div className={style.promo_subtitle}>
			Облачное SaaS-решение на основе ИИ<br/>— подключим ваши видеокамеры<br/>для получения, оценки и интерпретации
			данных
		</div>
		<a href={'#contacts'} className={style.promo_contacts}>
			Контакты
		</a>
		<img src={arristol} className={style.promo_image}/>
	</div>
}
