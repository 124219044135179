import React from 'react';
import style from "../LandingFoqus.module.css";
import {blogData} from "../../../data/blogData";
import {useNavigate} from "react-router-dom";
import logo from "../../../images/Foqus_white.png";
import {MenuOutlined} from "@ant-design/icons";
import {useUnit} from "effector-react";
import {toggleShowMenuEv} from "../../../models/newLandingModel";
import blog_splat_1 from '../../../images/blogImages/blog_splat_1.png'
import mobile_blog_splat_1 from '../../../images/newLangingSplats/mobile_blog_splat_1.png'

function BlogPromo({setTab}) {
	const firstPost = blogData[0]
	const navigate = useNavigate()
	const toggleShowMenu = useUnit(toggleShowMenuEv)

	return (
		<div className={style.blog_promo_wrapper}>
			<div className={style.container}>
				<div className={style.promo_header_wrapper}>
					<img src={logo} className={style.promo_logo} onClick={() => setTab('/')}/>
					<MenuOutlined className={style.promo_hamburger} onClick={toggleShowMenu}/>
				</div>
				<div className={style.blog_promo_title}>Our blog</div>
				<div className={style.blog_promo_post}>
					<img src={firstPost.promoImg}/>
					<div className={style.blog_promo_post_info}>
						<div>
							<div className={style.blog_promo_post_title}>{firstPost.title}</div>
							<div className={style.blog_promo_post_date}>{firstPost.date}</div>
						</div>
						<div className={style.blog_post_button} onClick={() => navigate(`/blog/${firstPost.id}`)}>
							READ MORE →
						</div>
					</div>
				</div>
			</div>
			<img src={blog_splat_1} id={'blog_splat_1'} />
			<img src={mobile_blog_splat_1} id={'mobile_blog_splat_1'} />
		</div>
	);
}

export default BlogPromo;
