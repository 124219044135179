import React, { useEffect } from 'react';

const DownloadPDF = () => {
  useEffect(() => {
    // URL to your PDF file in the public directory
    console.log(`${process.env.PUBLIC_URL}`)
    const url = `https://zyp-zyp-portal.s3.eu-west-1.amazonaws.com/Presentation.pdf`;

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Presentation.pdf'); // Specify the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }, []);

  return (
    <div>
      <p>Your download should begin shortly. If not, <a href={`https://zyp-zyp-portal.s3.eu-west-1.amazonaws.com/Presentation.pdf`} download="Presentation.pdf">click here</a>.</p>
    </div>
  );
};

export default DownloadPDF;