import React from 'react';
import style from '../LandingFoqus.module.css'
import {blogData} from "../../../data/blogData";
import BlogPostItem from "../blogSections/BlogPostItem";

function MainPosts() {
	return (
		<div className={style.mainPosts_wrapper}>
			<div className={style.container}>
				<div className={style.mainPosts_title}>
					We write about
				</div>
				<div className={style.mainPosts_posts_wrapper}>
					{
						blogData.slice(0,3).map((item, index) => {
							return <BlogPostItem key={`main-post-${index}`} {...item}/>
						})
					}
				</div>
			</div>
		</div>
	);
}

export default MainPosts;
