import style from './HReventlanding.module.css'
import * as React from "react";
import amazon from '../../images/amazon.png'
import beam from '../../images/beam.png'
import foqus_whole_white from '../../images/foqus_whole_white.png'
import partners_buro from '../../images/partners_buro.png'

export default function Organizers(){

	return (
		<div className={style.organizers_wrapper}>
			<div className={style.whiteTitle}>
				Организаторы
			</div>
      <div className={style.organizers_items_wrapper}>
        <img src={amazon}/>
        <img src={foqus_whole_white}/>
        <img src={beam}/>
        <img src={partners_buro}/>
      </div>
    </div>
  )
}
