// src/TermsAndConditions.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // -1 means go back to the previous page
  };

  return (
    <div style={{padding: '20px'}}>
      <h1>Условия и Положения</h1>

      <section>
        <h2>Сбор и Использование Данных</h2>
        <p>
          <strong>Электронная Почта:</strong> Мы будем собирать и использовать ваш электронный адрес для целей
          коммуникации, включая, но не ограничиваясь, рассылку новостей, специальных предложений и других промо
          материалов.
        </p>
        <p>
          <strong>Мобильные Сообщения:</strong> Мы будем использовать ваш мобильный номер для отправки специальных
          предложений и промо сообщений через SMS или другие мессенджеры.
        </p>
        <p>
          <strong>Идентификация по Лицу:</strong> Мы будем собирать и использовать данные для идентификации по лицу для
          управления нашей системой лояльности, что позволит нам предоставлять персонализированные скидки и награды,
          основанные на ранге.
        </p>
      </section>

      <section>
        <h2>Сторонние Сервисы</h2>
        <p>
          Для целей управления нашей системой лояльности и проверки идентификации по лицу, мы будем использовать
          сторонние SaaS-решения. Принимая эти условия, вы соглашаетесь, что ваши данные, включая идентификацию по лицу,
          могут быть обработаны этими сторонними сервисами.
        </p>
      </section>

      <section>
        <h2>Безопасность Данных</h2>
        <p>
          Мы обязуемся защищать ваши данные и будем внедрять соответствующие технические и организационные меры для
          обеспечения их безопасности. Однако мы не можем гарантировать абсолютную безопасность из-за природы цифровой
          передачи данных.
        </p>
      </section>

      <section>
        <h2>Права Пользователя</h2>
        <p>
          Вы имеете право в любой момент получить доступ к своим данным, исправить их или удалить. Для осуществления
          этих прав, пожалуйста, свяжитесь с нашей службой поддержки по адресу <a
          href="mailto:manager@foqus.live">manager@foqus.live</a>.
        </p>
      </section>

      <section>
        <h2>Согласие и Принятие</h2>
        <p>
          Проставляя галочку, вы подтверждаете, что прочитали, поняли и соглашаетесь с данными условиями и положениями.
          Вы также даёте согласие на сбор, использование и обработку ваших данных, как это описано выше.
        </p>
      </section>

      <section>
        <h2>Изменения Условий</h2>
        <p>
          Мы оставляем за собой право изменять эти условия в любое время. Любые изменения будут сообщены вам по
          электронной почте или мобильным сообщениям и вступят в силу немедленно после публикации.
        </p>
      </section>

      <button onClick={handleBackClick} style={{marginTop: '20px', padding: '10px 20px', fontSize: '16px'}}>
        Назад
      </button>
    </div>
  );
};

export default TermsAndConditions;
