import style from "../LandingFoqus.module.css";
import new_landing_laptop from '../../../images/new_landing_laptop.png'
import aws_logo from '../../../images/aws_logo.png'
import nvidia_logo from '../../../images/nvidia_logo.png'
import vector_right from '../../../images/vector_right.png'
import vector_left from '../../../images/vector_left.png'
import search from '../../../images/search.svg'
import search_color from '../../../images/search_color.svg'
import search_stars from '../../../images/search_stars.svg'
import union from '../../../images/union.png'
import union_mobile from '../../../images/union_mobile.png'

export default function Business({isPhone}){
	return <div className={style.business_wrapper}>
		<div className={style.container}>
			<div className={style.business_title}>
				Doing business is <div className={style.business_hard}>HARD</div>
			</div>
			<div className={style.business_subtitle}>
				Actually, it's Hard without us
			</div>
			<div style={{position: 'relative'}}>
				<div className={style.business_search_wrapper}>
					<div className={`${style.business_search_item} ${style.business_search_item_main}`}>
						<div style={{position: 'relative'}}>
							<img src={search_color} className={style.business_search_icon_main}/>
							<img src={search_stars} className={style.business_search_stars}/>
						</div>
						Show me the anomalies for last quarter
						<div className={style.business_search_cursor}/>
					</div>
					<div className={style.business_search_item}><img className={style.business_search_icon} src={search}/> Which class is the most disengaged?</div>
					<div className={style.business_search_item}><img className={style.business_search_icon} src={search}/> Generate a report on behavioral trends</div>
					<div className={style.business_search_item}><img className={style.business_search_icon} src={search}/> Show me happiness index average</div>
					<img src={isPhone ? union_mobile : union} className={style.business_search_union}/>
				</div>
				<div className={style.business_info_block} id={'info_block_1'}>
					Management without helicopter view is like navigating in the dark.
				</div>
				<div className={style.business_info_block} id={'info_block_2'}>
					Aggregated insights in a single interface simplify everything — making your operations seamless and your
					decisions smarter.
				</div>
				<div className={style.business_info_block} id={'info_block_3'}>
					With our partnership with AWS, we turn complexity into clarity, giving you the control and confidence to
					thrive.
				</div>
				<div style={{position: 'relative'}}>
					<img src={new_landing_laptop} className={style.business_laptop}/>
					<img src={vector_right} className={style.vector_right}/>
					<img src={vector_left} className={style.vector_left}/>
				</div>
			</div>
			<div className={style.business_partners}>
				<img src={nvidia_logo}/>
				<img src={aws_logo}/>
			</div>
		</div>
	</div>
}
