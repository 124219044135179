import style from '../OldLandingFoqus.module.css'
import howItWorks_1 from '../../../images/howItWorks_1.png'
import howItWorks_2 from '../../../images/howItWorks_2.png'
import howItWorks_3 from '../../../images/howItWorks_3.png'
import arrow_1 from '../../../images/arrows_1.png'
import arrow_2 from '../../../images/arrows_2.png'
import arrow_1_down from '../../../images/arrow_1_down.png'
import arrow_2_down from '../../../images/arrow_2_down.png'
import progress_arrow from '../../../images/progress_arrow.svg'
import progress_vertical from '../../../images/progress_vertical.png'
export default function HowItWorks({isPhone}){
	return <div className={style.howItWorks_wrapper}>
		<img src={progress_vertical} className={style.progress_vertical}/>
		<div className={style.howItWorks_title}>
			КАК РАБОТЕТ
		</div>
		<div className={style.howItWorks_cards_wrapper}>
			<div className={style.howItWorks_card}>
				<div className={style.howItWorks_card_title}>
					СБОР ДАННЫХ
				</div>
				<img src={howItWorks_1} className={[style.howItWorks_card_img, style.howItWorks_1].join(' ')}/>
				<div className={style.howItWorks_card_subtitle}>
					Интеграция
				</div>
				<ul className={style.howItWorks_card_desc}>
					<li>существующими камерами</li>
					<li>CRM , POS, Telegram, QR, 1C</li>
					<li>с третьих лиц: погода, ивенты</li>
				</ul>
			</div>
			{
				isPhone ?
					<img src={arrow_1_down} style={{marginTop: '35px', marginBottom: '35px'}}/>
					:
					<img src={arrow_1}/>
			}
			<div className={style.howItWorks_card}>
				<div className={style.howItWorks_card_title}>
					ОБРАБОТКА ДАННЫХ
				</div>
				<img src={howItWorks_2} className={[style.howItWorks_card_img, style.howItWorks_2].join(' ')}/>
				<div className={style.howItWorks_card_subtitle}>
					Гибридные модели
				</div>
				<ul className={style.howItWorks_card_desc}>
					<li>компьютерное зрение</li>
					<li>теория принятия решений+ИИ</li>
					<li>аналитика больших данных</li>
				</ul>
			</div>
			{
				isPhone ?
					<img src={arrow_2_down} style={{marginTop: '80px', marginBottom: '77px'}}/>
					:
					<img src={arrow_2}/>
			}
			<div className={style.howItWorks_card}>
				<div className={style.howItWorks_card_title}>
					ВЫВОД ДАННЫХ
				</div>
				<img src={howItWorks_3} className={[style.howItWorks_card_img, style.howItWorks_3].join(' ')}/>
				<div className={style.howItWorks_card_subtitle}>
					BI Визуализация
				</div>
				<ul className={style.howItWorks_card_desc}>
					<li>управленческие отчеты</li>
					<li>аналитические дешборды</li>
					<li>мгновенные уведомления</li>
				</ul>
			</div>
		</div>
		<div className={style.howItWorks_progress}>
			<div className={style.howItWorks_progress_point}/>
			<img src={progress_arrow}/>
			<div className={style.howItWorks_progress_point}/>
			<img src={progress_arrow}/>
			<div className={style.howItWorks_progress_point}/>
		</div>
		<div className={style.howItWorks_progress_time}>
			<div className={style.point_time}>5 секунд</div>
			<div className={style.point_time}>2 минуты</div>
			<div className={style.point_time}>3 минуты</div>
		</div>
	</div>
}
