import style from './HReventlanding.module.css'
import foqus_white from "../../images/Foqus_white.png";
import header_chart from "../../images/header_chart.png"
import * as React from "react";

export default function Promo(props){
	const { isPhone } = props
	return (
		<div style={{position: 'relative'}}>
			{
				!isPhone ?
					<div className={style.logo_wrapper}>
						<img src={foqus_white} className={style.foqus_logo}/>
					</div>
					:
					''
			}
			{
				!isPhone ?
					<div className={style.header_chart_wrapper}>
						<img src={header_chart} className={style.header_chart}/>
					</div>
					:
					''
			}
			<div className={style.mainTitle}>
				HR RETRO<br/>
				SPECTIVE
			</div>
			<div className={style.secondaryTitle}>
				Уникальное мероприятие, посвящённое актуальным вопросам и новым тенденциям в сфере управления персоналом
			</div>
			<div className={style.frames_wrapper}>
				<div className={style.borderFrameFirst}>
					25 июня | 09:30–13:00
				</div>
				<div className={style.borderFrameSecond}>
					пр. Аль-Фараби 128/7, отель SADU
				</div>
			</div>
			{/*FIXME Second Screen*/}
			<div className={style.promo_buttons_wrapper}>
				<a href={'#regform'} className={style.buttonRegistration}>Зарегистрироваться</a>
				<a href={'https://t.me/HRTechAssistantBot'} target={'_blank'} className={style.buttonTelegramRegistration}>Регистрация в телеграмм боте</a>
			</div>
		</div>
	)
}
