import InfoBlock from "./InfoBlock";
import Icon from '@ant-design/icons'
import style from './Registration.module.css'

const FaceSvg = () => {
	return <svg
		xmlns="http://www.w3.org/2000/svg"
		width={133}
		height={133}
		fill="none"
	>
		<path
			fill="#87898F"
			fillOpacity={0.34}
			fillRule="evenodd"
			d="M6.754 36.497V21.561c0-8.165 6.642-14.807 14.806-14.807h14.937a3.377 3.377 0 0 0 0-6.754H21.561C9.672 0 0 9.672 0 21.56v14.937a3.377 3.377 0 0 0 6.754 0Zm29.743 89.749a3.377 3.377 0 0 1 0 6.754H21.561C9.672 133 0 123.328 0 111.439V96.503a3.377 3.377 0 0 1 6.754 0v14.936c0 8.165 6.642 14.807 14.806 14.807h14.937ZM133 96.503v14.936c0 11.889-9.672 21.561-21.561 21.561H96.503a3.377 3.377 0 0 1 0-6.754h14.936c8.165 0 14.807-6.642 14.807-14.807V96.503a3.377 3.377 0 0 1 6.754 0Zm0-74.942v14.936a3.377 3.377 0 0 1-6.754 0V21.561c0-8.165-6.642-14.807-14.807-14.807H96.503a3.377 3.377 0 0 1 0-6.754h14.936C123.328 0 133 9.672 133 21.56ZM89.805 98.3a3.507 3.507 0 0 0-4.76-5.152 27.254 27.254 0 0 1-18.545 7.251 27.254 27.254 0 0 1-18.546-7.251 3.507 3.507 0 0 0-4.759 5.152 34.248 34.248 0 0 0 23.305 9.113A34.248 34.248 0 0 0 89.805 98.3ZM73.514 50.135v24.937c0 4.942-4.02 8.962-8.962 8.962h-2.858a3.507 3.507 0 1 1 0-7.014h2.858a1.95 1.95 0 0 0 1.948-1.948V50.135a3.507 3.507 0 1 1 7.014 0Zm24.158 9.806V49.94a3.312 3.312 0 0 0-6.624 0v10a3.312 3.312 0 1 0 6.624 0Zm-61.565 0a3.312 3.312 0 0 0 6.624 0V49.94a3.312 3.312 0 0 0-6.624 0v10Z"
			clipRule="evenodd"
		/>
	</svg>
}

const FaceIcon = (props) => {
	return <Icon className={props.className} component={FaceSvg} />
}

export default function InfoPage() {
	return <>
		<InfoBlock
			description={'Селфи-фотография понадобится нам временно, только для демонстрации возможностей нашего решения'}
		/>
		<FaceIcon className={style.face_icon}/>
	</>
}
