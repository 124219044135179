import style from "./HReventlanding.module.css";
import * as React from "react";

export default function Program(){
	return (
		<div className={style.program_wrapper}>
			<div className={style.whiteTitle}>
				Программа
			</div>
			<div className={style.program_part_wrapper}>
				<div className={style.programmPart}>
					<div className={style.time}>09:30-10:00</div>
					<div className={style.partName}>Регистрация & Welcome Drink</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>10:00-10:15</div>
					<div className={style.partName}>Приветствие участников</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>10:15-10:40</div>
					<div className={style.partName}>Будущее корпоративной культуры: тенденции и новации</div>
					<div className={style.whoSpeach}>Булат Садыков</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>10:40-11:10</div>
					<div className={style.partName}>Будущее HR: Использование поведенческих данных для
						стратегического преимущества
					</div>
					<div className={style.whoSpeach}>Серик Жарданбек</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>11:15-11:55</div>
					<div className={style.partName}>Coffee Break & Интерактивы</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>12:00-12:25</div>
					<div className={style.partName}>Конкуренция за талантливую молодёжь и автоматизация рекрутинга</div>
					<div className={style.whoSpeach}>Санжар Кенжеханұлы</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>12:30-12:45</div>
					<div className={style.partName}>Карьера в Big Tech: Как Опционы Удерживают Таланты</div>
					<div className={style.whoSpeach}>Алмаз Рахымбеков</div>
				</div>
				<div className={style.programmPart}>
					<div className={style.time}>12:50-13:00</div>
					<div className={style.partName}>Призы для участников</div>
				</div>
			</div>
		</div>
	)
}
