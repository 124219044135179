import style from './Registration.module.css'
import CustomSlider from "./CustomSlider";

export default function InfoBlock(props) {

	const { title, description } = props

	return <div className={style.info_block}>
		{
			title ? <div className={style.info_title}>Рады приветствовать вас!</div> : ''
		}
		<div className={style.info_point} style={{marginTop: title ? '10px' : 'unset'}}>
			{
				description
			}
		</div>
	</div>
}
